@import '../../assets/css/global.scss';

#nav {
  background-color: $dark-grey;
  color: $white;
  z-index: 9;
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;

  &.hidden {
    left: -100%;
  }

  @media screen and (min-width: $breakpoint-large) {
    left: 0!important;
    width: 20vw;
    position: relative;
    min-height: 90vh;
  }
}

.nav {
  &--title {
    margin: 0;
    padding: 0 10px;
  }

  &--destinations {
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
  }

  &--destinations-item {
    margin: 0;
    padding: 3px 20px;
    cursor: pointer;
    font-size: 0.8em;

    @media screen and (min-width: $breakpoint-small) {
      padding: 10px 20px;
      font-size: 1em;
    }

    @media screen and (min-width: $breakpoint-large) {
      padding: 10px 20px;
      font-size: 1.1em;
    }

    &:hover {
      background-color: $grey;
    }
  }

  &--back-link {
    font-weight: bold;
  }
}

/*
 * Colors
 */

$dark-grey: #303030;
$grey: #505050;
$white: #EEEEEE;


/*
 * Breakpoints
 */

$breakpoint-small: "600px";
$breakpoint-medium: "900px";
$breakpoint-large: "1200px";

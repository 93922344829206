@import '../../assets/css/global.scss';

#main {
  position: relative;
  // display: flex;
  // flex-direction: column;
  min-height: 80vh;
  width: 100vw;

  @media screen and (min-width: $breakpoint-large) {
    display: flex;
    flex-direction: row;
    min-height: 90vh;
  }
}

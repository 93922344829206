@import '../../assets/css/global.scss';

.map {
  &--popup-suggestion {
    position: absolute;
    width: 50%;
    height: 50%;
    background-color: $white;
  }
}

.map-main {
  width: 100%;
  min-height: 80vh;

  @media screen and (min-width: $breakpoint-large) {
    width: 80%;
  }
}

@import '../../assets/css/global.scss';

.header {
  display: flex;
  flex-direction: column;
  background-color: $dark-grey;
  color: $white;
  min-height: 15vh;

  @media screen and (min-width: $breakpoint-large) {
    flex-direction: row;
    min-height: 10vh;
  }

  &--title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    min-height: 10vh;

    @media screen and (min-width: $breakpoint-small) {
      flex-basis: 50%;
      flex-direction: row;
      padding: 0 30px;
    }
  }

  &--title {
    margin: 0;
    padding: 5px 0;
    font-size: 1.7em;

    @media screen and (min-width: $breakpoint-medium) {
      font-size: 2em;
    }
  }

  &--greeting-container {
    display: flex;
    min-height: 20px;
    flex-direction: row;

    @media screen and (min-width: $breakpoint-large) {
      flex-direction: column;
    }
  }

  &--greeting {
    margin-right: 20px;
  }

  &--button-username,
  &--button-login,
  &--button-nav,
  &--button-search {
    font-size: 0.75em;
    background-color: $dark-grey;
    border: none;
  }

  &--button-nav,
  &--button-search {
    padding: 20px 50px;
  }

  &--search-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 10vh;

    @media screen and (min-width: $breakpoint-large) {
      flex-basis: 50%;
    }
  }

  &--search-input {
    height: 50px;
    width: 100%;
    max-width: 500px;
    color: $dark-grey;
    padding-left: 10px;
    position: absolute;
    top: 20vh;
    z-index: 99;

    &.hidden {
      display: none;
    }

    @media screen and (min-width: $breakpoint-small) {
      display: block !important;
      position: relative;
      top: 0;
    }
  }

  &--search-input-button {
    color: $dark-grey;
    height: 50px;
    position: absolute;
    top: 20vh;
    right: 0;
    z-index: 99;

    &.hidden {
      display: none;
    }

    // @media screen and (min-width: $breakpoint-small) {
    //   display: block !important;
    //   position: relative;
    //   top: 0;
    // }
  }

  &--button-login {
    display: none;

    @media screen and (min-width: $breakpoint-small) {
      display: inline-block;
    }
  }

  &--button-search {
    @media screen and (min-width: $breakpoint-small) {
      display: none;
    }
  }

  &--button-nav {
    @media screen and (min-width: $breakpoint-large) {
      display: none;
    }
  }
}
